@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;700&family=Playfair+Display:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --color-primary: #EC4899; /* pink-500 */
    --color-primary-light: #F9A8D4; /* pink-300 */
    --color-secondary: #F472B6; /* pink-400 */
    --color-secondary-light: #FCE7F3; /* pink-100 */
    --color-background: #FDF2F8; /* pink-50 */
    --color-text: #1F2937; /* gray-800 */
    --font-primary: 'Heebo', sans-serif;
    --font-secondary: 'Heebo', sans-serif; /* Changed to sans-serif as both are now Heebo */
}

body {
    font-family: var(--font-primary);
    background-color: var(--color-background);
    color: var(--color-text);
}